@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "node_modules/gentelella/vendors/fontawesome/scss/font-awesome.scss";
@import "node_modules/gentelella/vendors/bootstrap-daterangepicker/daterangepicker.scss";
@import "node_modules/gentelella/vendors/bootstrap-progressbar/scss/bootstrap-progressbar-3.3.0-3.x.x.scss";
@import "node_modules/gentelella/src/scss/custom.scss";

.nav_title {
  float: none;
}

.x_title h2 {
  line-height: 1.2;
}

.site_title {
  padding-right: 10px;
  padding-top: 15px;
  height: auto;
  img {
    width: auto;
    height: auto;
    margin: 0 auto;
    max-height: 37px;
  }
}

.nav-md {
  .icon_large {
    display: block;
  }
  .icon_small {
    display: none;
  }
}

.nav-sm {
  .icon_large {
    display: none;
  }
  .icon_small {
    display: block;
  }
}

.panel_toolbox {
  min-width: unset;
}

.config__container {
  background: white;
}

.config__container-empty {

}

ul.box__list, ol.box__list {
  list-style: none;
  margin: 0;
  padding: 0;
  min-height: 75px;
  > li {
    display: block;
    float: left;
  }
}

.drop__box {
  height: auto;
}

.ui-draggable-handle {
  cursor: move;
}

.qrcode__item {
  width: 64px;
  height: 75px;
  text-align: center;
  padding: 5px;
  margin: 5px;
  i {
    color: rgb(38, 185, 154);
    font-size: 50px;
    display: block;
  }
  .qrcode__hash {
    display: block;
  }
}

.config__box {
  > ul, ol {
    border: 3px dashed #EFEFEF;
    border-radius: 3px;
    &.ui-droppable-active {
      border-color: #00AEEF;
    }
    &.ui-droppable-hover {
      border-color: #00A000;
    }
  }
}

.config__box-qrcode {

}

img.logo-inline {
  height: 40px;
  width: auto;
}

.epi.form_wizard .stepContainer {
  overflow: visible;
}

.thumbnail.qrholder {
  height:auto;
}

.thumbnail.qrholder img {
  display: block;
}

#login-dp{
  min-width: 250px;
  padding: 14px 14px 0;
  overflow:hidden;
  background-color:rgba(255,255,255,.8);
}
#login-dp .help-block{
  font-size:12px
}
#login-dp .bottom{
  background-color:rgba(255,255,255,.8);
  border-top:1px solid #ddd;
  clear:both;
  padding:14px;
}

#login-dp .form-group {
  margin-bottom: 10px;
}

@media(max-width:768px){
  #login-dp{
    background-color: inherit;
    color: #fff;
  }
  #login-dp .bottom{
    background-color: inherit;
    border-top:0 none;
  }
}